import demoPNG from "../img/demo.png";

const ProjectList = [
  {
    name: "Ecomers",
    description: "This web using react + ts and database using google spreadsheet",
    url: "https://demo.bwx1y.my.id",
    photo: demoPNG,
  },
];

export default ProjectList;
