import HTML from "../img/html.png";
import CSS from "../img/css-3.png";
import Javascript from "../img/js.png";

const skils = [
  {
    icon: HTML,
    title: "HTML",
    about: "aku telah menggunakan bahasa ini untuk membuat beberapa project di replit. \naku telah menggunakannya sekitar 2 tahunan lebih.",
  },
  {
    icon: CSS,
    title: "CSS",
    about: "aku tidak terlalu mengerti tentang styling tetapi aku bisanya menggunakan framework untuk mengberiakan style kepada sebuah website. \ncontohnya web ini aku menggunakan bootstrap untuk stylenya ",
  },
  {
    icon: Javascript,
    title: "Javascript",
    about: "aku sering menggunakan bahasa ini dan web ini pun menguunankan bahasa ini dengan react untuk compiler.",
  },
];

export default skils;
