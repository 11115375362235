import link from "./MyLink.js";
import skils from "./MySkils.js";
import ProjectList from "./Project.js";

let data = {};

data.MyLink = link;
data.skils = skils;
data.ProjectList = ProjectList;

export default data;
